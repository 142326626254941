<template>
  <div style="text-align: center;">
    <img :src="notFound" alt="页面找不到" style="height: 50rem;">
    <el-button style="display: block;margin: 20px auto;" @click="toIndex">返回首页</el-button>
  </div>
</template>
<script>
import notFound from '@/assets/image/404.png'

export default {
  name: 'notFound',
  data: () => {
    return {
      notFound
    }
  },
  methods: {
    toIndex() {
      this.$router.push({path: '/resource/index'})
    }
  }
}
</script>
<style scoped></style>
